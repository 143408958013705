import * as React from 'react'
import Layout from '@components/Layout'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import {
  MdFileDownload as _MdFileDownload,
  MdPhoto as _MdPhoto
} from 'react-icons/md'

import { FaGlobeAmericas as _FaGlobeAmericas } from 'react-icons/fa'

import UI, { H4, Flex, Box, Link } from '@components/UI'
import { Icon } from '@components/Social'

const MdFileDownload = styled(_MdFileDownload).attrs({
  pr: 1
})`
  padding-top: 2px;
  ${color};
  ${space}
`
const MdPhoto = styled(_MdPhoto).attrs({
  pr: 1
})`
  ${color}
  ${space}
`

const FaGlobeAmericas = styled(_FaGlobeAmericas).attrs({
  color: 'dark',
  pr: 1
})`
  ${color}
  ${space}
`
// const H4 = styled(UI.H4).attrs({})``

// STODO add types using graphql generated types
type PhotoProps = {
  pageContext: {
    childImageSharp: any
    photoInfo?: { link?: string; photographer?: string }
  }
}

const Photo = ({
  pageContext: {
    childImageSharp,
    photoInfo: { label, title } = {
      link: null,
      photographer: null
    }
  }
}: PhotoProps) => {
  return (
    <Layout goBackTo="photos" mt={2} title="Photos">
      <Flex flexDirection="column">
        <Flex pb={1} flexDirection="row" justifyContent="space-between">
          <Flex>
            <FaGlobeAmericas />
            <H4 pb={1}>{label}</H4>
          </Flex>
          <Link href={childImageSharp.original.src} download={title}>
            <Flex>
              <MdFileDownload color="dark" />
              <H4>Download (High Resolution)</H4>
            </Flex>
          </Link>
        </Flex>
        <Img
          alt={title}
          key={childImageSharp.fluid.src}
          fluid={childImageSharp.fluid}
        />
      </Flex>
    </Layout>
  )
}

export default Photo
